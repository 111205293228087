import { useState, useEffect } from 'react';
import styled from 'styled-components';

const CardPrice = styled.p`
  font-size: 0.8rem;
  letter-spacing: 1.0px;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: 10px;
  padding: 1px;
`;

const CardTitle = styled.p`
  font-size: 0.8rem;
  margin: 0;
  letter-spacing: 1.0px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  padding: 1px;
`;

const StyledCard = styled.div`
  width: 23vw;
  height: 32vw;
  min-height: 300px;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  text-decoration: none;
  color: black;
  overflow: hidden;
  position: relative;
    @media (max-width: 1000px) {
    width: 45vw;
    height: 70vw;
    }
    @media (max-width: 768px) {

      width: 100vw;
      height: 140vw;
      min-height: 200px;
    }

`;


const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
`;

const Card = ({ src1, src2, title, price }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // set initial value based on window width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <StyledCard 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardImageContainer>
        <CardImage src={src1} isVisible={!isHovered} />
        <CardImage src={src2} isVisible={isHovered} />
      </CardImageContainer>
      <CardTitle>{title}</CardTitle>
      <CardPrice>{price}</CardPrice>
    </StyledCard>
  );
};

export default Card;