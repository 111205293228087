//FILENAME: src/Components/SideCart.jsx

import React, { useContext, useEffect, useRef } from "react";
import { CartContext } from "../Contexts/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import { MdKeyboardArrowDown } from "react-icons/md";




import ApplePayLogo from '../Images/apple-pay-svgrepo-com-2.png';
import VisaCardLogo from '../Images/visa-svgrepo-com.png';

import MasteroLogo from '../Images/maestro-old-svgrepo-com.png';
import MasterCardLogo from '../Images/mastercard-logo-mastercard-logo-png-vector-download-19.png';
import AmericanExpressLogo from '../Images/amex-svgrepo-com.png';
import GPayLogo from '../Images/google-pay-icon-svgrepo-com.png';
import JCBLogo from '../Images/jcb-svgrepo-com.png';
import CurrencyContext from "../Contexts/CurrencyContext";




import axios from "axios";

import styled from "styled-components";



const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 470px;  // Set the width to the final desired width
  height: 100%;
  background: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  transform: translateX(100%); // Start off screen
  transition: transform 0.3s ease-out;  // Transition the transform property
  
  &.open {
    transform: translateX(0);  // Bring on screen when open
  }

  // Add responsiveness for mobile devices
  @media (max-width: 500px) {
    width: 100%;  // Take the full width on mobile devices
    box-shadow: none;  // Optionally remove the shadow for a cleaner look
  }

  ::-webkit-scrollbar {
    width: 0; /* Hide scrollbar for Chrome, Safari and Opera */
}

::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scroll thumb */
    border-radius: 2px; /* Rounded corners on the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
}

::-webkit-scrollbar-track {
    background-color: #f5f5f5; /* Color of the track */
}

`;


const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);  // semi-transparent black

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 0;  // Ensure the backdrop is below the side cart but above other content
`;


const ProductImage = styled.img`
  width: 110px;
  height: 150px;
  margin-right: 10px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 135px;  // Adjust to your preferred width for mobile
    height: 200px; // Adjust to your preferred height for mobile
  }
`;


const CartHeader = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 25px;
  letter-spacing: 2px;
  margin-top: 5px;

  @media (max-width: 768px) {
    justify-content: center;
    position: relative; // Add this
    padding: 6px;
    font-size: 15px;
  }
`;


const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 3px;
  
 
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: stretch;  // This ensures that the children (image and details) take the full height
  
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const DetailsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;  // Aligns children to the top
  flex-grow: 1;  // Allows this container to grow and push the DetailsBottom to the bottom of ProductDetails

`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14.5px;
  
`;


const DetailsBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; // Ensure it takes full width

  @media (max-width: 768px) {
    flex-direction: column; // Stack vertically on mobile
  }
`;




const CartTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; // This is optional, but it ensures vertical alignment if your items have different heights
  width: 100%; // Take the full width of its parent
  margin-top: 5px;
`;

const TotalLabel = styled.span`
  font-size: 14px;
`;

const TotalPrice = styled.span`
  font-size: 153eepx;
`;



const RemoveFromCartButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: gray;
  margin-top: 6px;
`;

const RemoveText = styled.div`
  border-bottom: 1px solid lightgray;
  line-height: 1;
  font-size: 14.5px;
`;

const CheckoutButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: black;
  color: #fff;
  border: none;
  cursor: pointer;
  height: 55px;
  font-size: 12px;
  letter-spacing: 1px;

  &:hover {
        background-color: #333;
    }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;  // Increase the size of the '×' character
  cursor: pointer;  // Change the cursor to a hand pointer when hovering
  color: #666;      // A softer color which isn't as harsh as black
  transition: color 0.3s ease;  // Smooth transition for hover effect

  &:hover {
    color: #444;    // Darken the color slightly on hover
  }

  &:focus {
    outline: none;  // Remove the default focus outline
  }

  @media (max-width: 768px) {
    position: absolute;
    right: 10px; // Adjust as needed
    top: 50%;
    transform: translateY(-50%);
  }

`;



const QuantityContainer = styled.div`
  display: flex;
  width: 147px;
  height: 40px;
  border: 1px solid lightgrey;
  background-color: white;
  align-items: center;

  @media (max-width: 768px) {
    width: calc(100% - 10px); // Subtracting 10px (5px on each side)
    margin: 5px; // 5px margin on all sides
  }
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;  // Increase font size for visibility
  cursor: pointer;
  flex: 1;  // This ensures that the '-' and '+' buttons take up equal space
  &:focus {
    outline: none;
  }
`;

const QuantityDisplay = styled.span`
  font-size: 15px;  // Increase font size for visibility
  flex: 0;  // This makes the display area twice as wide as the buttons
  text-align: center;
`;




const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


const CartContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;


const CartFooter = styled.div`
  padding: 10px 22px;
  border-top: 1px solid #f5f5f5;
  background: #fff;
`;


const CartItem = styled.div``;



const ProductName = styled.span``;

const ProductPrice = styled.span``;










const ItemTotal = styled.span``;



const PaymentLogosContainer = styled.div`
  display: flex;
  justify-content: center; // Center the logos horizontally
  margin-top: 24px; // Add some space between the CHECKOUT button and the logos
  margin-bottom: 15px;
`;

const PaymentLogo = styled.img`
  width: 40px; // Adjust as needed
  height: auto;
  margin: 0 5px; // Spacing between logos
  border: 1px solid #ccc; // Adjust the color as needed
  border-radius: 4px; // Adjust for desired curvature
  height: 30px;
  object-fit: contain;
  
  
`;






const CartLabel = styled.div`
  display: flex;
  align-items: center;
  
`;


const EmptyCart = styled.div`
  align-items: center;
  align-content: center;
`;



const SizeDropdown = styled.select`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  appearance: none;
  padding-right: 30px; // Make space for the custom arrow
  padding-left: 10px;
  font-size: 15.5px;

`;

const DropdownArrow = styled(MdKeyboardArrowDown)`
  position: absolute;
  right: 10px; // Adjust as needed
  top: 50%;
  transform: translateY(-50%); // Vertically center the arrow
  pointer-events: none; // Ensure the dropdown still works when clicking on the arrow
`;

const SelectContainer = styled.div`
  display: flex;
  
  width: 147px;
  height: 40px;
  border: 1px solid lightgrey;
  background-color: white;
  align-items: center;
  position: relative; 

  // Media query for mobile devices
  @media (max-width: 768px) {
    width: calc(100% - 10px); // Subtracting 10px (5px on each side)
    margin: 0 5px; // 5px margin on both sides
    ;
  }
`;

const CenteredCartContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column; // Add this to stack child elements vertically
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  margin-top: 240px;
  padding: 30px;
  font-size: 16px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 15px;
    ;
  }
`;



const SideCart = ({ onClose, isOpen }) => {
  const { conversionRate, currencySymbol, currencyCode } = useContext(CurrencyContext);


  const stripePromise = loadStripe(
    "pk_live_51NlgecIq83zes8nzIMqyE68lx87uCb3MqstAFGnreumGBn7uXxx2wbEAsgUhGmB8Or0stKBtdgagHhqSrjgUyUMk00WarWzvh8"
  );


  const handleCheckout = async () => {
    const cartData = cart.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      size: item.size,
      option: item.option, // Make sure to include the option here
      currency: currencyCode.toLowerCase(),
      StripeID: item.StripeID,
    }));
    console.log(cartData);
    debugger;

    const { data } = await axios.post('https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/checkout', { cartData });


    const stripe = await stripePromise;
    await stripe.redirectToCheckout({ sessionId: data.id });
};



const {
  cart,
  removeFromCart,
  isCartOpen,
  setIsCartOpen,
  updateCartItemQuantity,
  updateCartItemSize,
} = useContext(CartContext);



const getTotalPrice = () => {
  const total = cart.reduce((acc, item) => acc + (item.total * conversionRate), 0);
  return Number(total.toFixed(2));
};

const handleSizeChange = (cartItemId, newSize) => {
  updateCartItemSize(cartItemId, newSize);
};



  useEffect(() => {
    if (isOpen || isCartOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to reset the overflow property when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, isCartOpen]);


  const sideCartRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideCartRef.current && !sideCartRef.current.contains(event.target)) {
        if(onClose) onClose();
        setIsCartOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, setIsCartOpen]);

  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);



  return [
    <Backdrop
      key="backdrop"
      isOpen={isOpen || isCartOpen}
      onClick={() => {
        if(onClose) onClose();
        setIsCartOpen(false);
      }}
    />,
    <Container key="container" ref={sideCartRef} className={isOpen || isCartOpen ? "open" : ""}>
      <Wrapper>
        <CartHeader>
          <CartLabel>
            {totalItems > 0 ? `CART (${totalItems})` : 'CART'}
          </CartLabel>


          <CloseButton onClick={() => {
            if(onClose) onClose();
            setIsCartOpen(false);
          }}>
            &times;
          </CloseButton>
        </CartHeader>



<CartContent>
          {cart.length === 0 ? (
    <CenteredCartContent>
        <EmptyCart>There are currently no items in the shopping cart</EmptyCart>
        

    </CenteredCartContent>
) : (
                  cart.map((item, index) => (
                    
                      <ProductContainer key={index}>
                          <ProductImage src={item.image} alt={item.name} />
                          <ProductDetails>
                              <DetailsTop>
                                  <ProductInfo>
                                  <ProductName>
            {item.name}
            {item.id === 3 ? ` (${item.option})` : ''}
          </ProductName>
                                      <ProductPrice>{currencySymbol}{(Number(Math.round(item.price * conversionRate * 10) / 10).toFixed(2))}</ProductPrice>
                                  </ProductInfo>
                                  <RemoveFromCartButton onClick={() => removeFromCart(item.cartItemId)}>
                                      <RemoveText>Remove</RemoveText>
                                  </RemoveFromCartButton>
                              </DetailsTop>
                              <DetailsBottom>
                              <SelectContainer>
            <SizeDropdown value={item.size} onChange={(e) => handleSizeChange(item.cartItemId, e.target.value)}>
                {['XS','S', 'M', 'L', 'XL'].map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </SizeDropdown>
            <DropdownArrow />
        </SelectContainer>

                    <QuantityContainer>
                      <QuantityButton
                        onClick={() =>
                          updateCartItemQuantity(item.cartItemId, item.quantity - 1)
                        }
                      >
                        -
                      </QuantityButton>
                      <QuantityDisplay>{item.quantity}</QuantityDisplay>
                      <QuantityButton
                        onClick={() =>
                          updateCartItemQuantity(item.cartItemId, item.quantity + 1)
                        }
                      >
                        +
                      </QuantityButton>
                    </QuantityContainer>
                  </DetailsBottom>
                </ProductDetails>
              </ProductContainer>
            ))
          )}
        </CartContent>
        {cart.length !== 0 && (
          <CartFooter>
            <CartTotal>
              <TotalLabel>TOTAL</TotalLabel>
              <TotalPrice>{currencySymbol}{getTotalPrice()}</TotalPrice>
            </CartTotal>
            <CheckoutButton onClick={handleCheckout}>CHECK OUT</CheckoutButton>

                  <PaymentLogosContainer>
  <PaymentLogo src={ApplePayLogo} alt="Apple Pay" />
  <PaymentLogo src={MasterCardLogo} alt="Apple Pay" />
  <PaymentLogo src={VisaCardLogo} alt="Apple Pay" />
  <PaymentLogo src={AmericanExpressLogo} alt="Apple Pay" />
  <PaymentLogo src={MasteroLogo} alt="Apple Pay" />
  <PaymentLogo src={GPayLogo} alt="Apple Pay" />
  <PaymentLogo src={JCBLogo} alt="Apple Pay" />
 
</PaymentLogosContainer>
              </CartFooter>
          )}
      </Wrapper>
    </Container>
  ];

};


export default SideCart;