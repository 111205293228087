import { createContext } from 'react';

const CurrencyContext = createContext({
  conversionRate: 1,
  currencySymbol: '€',
  currencyCode: 'EUR',
  setConversionRate: () => {},
  setCurrencySymbol: () => {},
  setCurrencyCode: () => {},
});

export default CurrencyContext; 