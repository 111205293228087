

const countryToCurrency = {
    'US': { name: 'United States', code: 'USD', symbol: '$' },
    'CA': { name: 'Canada', code: 'CAD', symbol: '$' },
    'GB': { name: 'United Kingdom', code: 'GBP', symbol: '£' },
    'AU': { name: 'Australia', code: 'AUD', symbol: '$' },
    'FR': { name: 'France', code: 'EUR', symbol: '€' },
    'DE': { name: 'Germany', code: 'EUR', symbol: '€' },
    'IT': { name: 'Italy', code: 'EUR', symbol: '€' },
    'ES': { name: 'Spain', code: 'EUR', symbol: '€' },
    'NL': { name: 'Netherlands', code: 'EUR', symbol: '€' },
    'BE': { name: 'Belgium', code: 'EUR', symbol: '€' },
    'IE': { name: 'Ireland', code: 'EUR', symbol: '€' },
    'PT': { name: 'Portugal', code: 'EUR', symbol: '€' },
    'AT': { name: 'Austria', code: 'EUR', symbol: '€' },
    'FI': { name: 'Finland', code: 'EUR', symbol: '€' },
    'BR': { name: 'Brazil', code: 'BRL', symbol: 'R$' },
    'MX': { name: 'Mexico', code: 'MXN', symbol: '$' },
    'AR': { name: 'Argentina', code: 'ARS', symbol: '$' },
    'JP': { name: 'Japan', code: 'JPY', symbol: '¥' },
    'CN': { name: 'China', code: 'CNY', symbol: '¥' },
    'HK': { name: 'Hong Kong', code: 'HKD', symbol: 'HK$' },
    'IN': { name: 'India', code: 'INR', symbol: '₹' },
    'RU': { name: 'Russia', code: 'RUB', symbol: '₽' },
    'ZA': { name: 'South Africa', code: 'ZAR', symbol: 'R' },
    'SG': { name: 'Singapore', code: 'SGD', symbol: 'S$' },
    'MY': { name: 'Malaysia', code: 'MYR', symbol: 'RM' },
    'NZ': { name: 'New Zealand', code: 'NZD', symbol: '$' },
    'SE': { name: 'Sweden', code: 'SEK', symbol: 'kr' },
    'CH': { name: 'Switzerland', code: 'CHF', symbol: 'CHF' },
    'NO': { name: 'Norway', code: 'NOK', symbol: 'kr' },
    'DK': { name: 'Denmark', code: 'DKK', symbol: 'kr' },
    'PL': { name: 'Poland', code: 'PLN', symbol: 'zł' },
    'CZ': { name: 'Czech Republic', code: 'CZK', symbol: 'Kč' },
    'HU': { name: 'Hungary', code: 'HUF', symbol: 'Ft' },
    'RO': { name: 'Romania', code: 'RON', symbol: 'lei' },
    'GR': { name: 'Greece', code: 'EUR', symbol: '€' },
    'AE': { name: 'United Arab Emirates', code: 'AED', symbol: 'د.إ' },
    'IL': { name: 'Israel', code: 'ILS', symbol: '₪' },
    'KR': { name: 'South Korea', code: 'KRW', symbol: '₩' },
    'ID': { name: 'Indonesia', code: 'IDR', symbol: 'Rp' },
    'PH': { name: 'Philippines', code: 'PHP', symbol: '₱' },
    'TH': { name: 'Thailand', code: 'THB', symbol: '฿' },
    'TR': { name: 'Turkey', code: 'TRY', symbol: '₺' },
    'SA': { name: 'Saudi Arabia', code: 'SAR', symbol: 'ر.س' },
    'QA': { name: 'Qatar', code: 'QAR', symbol: 'ر.ق' },
    'KW': { name: 'Kuwait', code: 'KWD', symbol: 'د.ك' },
    'CO': { name: 'Colombia', code: 'COP', symbol: '$' },
    'CL': { name: 'Chile', code: 'CLP', symbol: '$' },
    'PE': { name: 'Peru', code: 'PEN', symbol: 'S/.' },
    'VE': { name: 'Venezuela', code: 'VES', symbol: 'Bs.' },
    'UY': { name: 'Uruguay', code: 'UYU', symbol: '$' },
    'VN': { name: 'Vietnam', code: 'VND', symbol: '₫' },
    'NG': { name: 'Nigeria', code: 'NGN', symbol: '₦' },
    'EG': { name: 'Egypt', code: 'EGP', symbol: '£' },
    'UA': { name: 'Ukraine', code: 'UAH', symbol: '₴' },
    'PK': { name: 'Pakistan', code: 'PKR', symbol: '₨' },
    'BD': { name: 'Bangladesh', code: 'BDT', symbol: '৳' },
    'KE': { name: 'Kenya', code: 'KES', symbol: 'Sh' },
    'TW': { name: 'Taiwan', code: 'TWD', symbol: 'NT$' },
    'LK': { name: 'Sri Lanka', code: 'LKR', symbol: 'Rs' },
    'JO': { name: 'Jordan', code: 'JOD', symbol: 'JD' },
    'IS': { name: 'Iceland', code: 'ISK', symbol: 'kr' },
    'LV': { name: 'Latvia', code: 'EUR', symbol: '€' },
    'LT': { name: 'Lithuania', code: 'EUR', symbol: '€' },
    'SK': { name: 'Slovakia', code: 'EUR', symbol: '€' },
    'SI': { name: 'Slovenia', code: 'EUR', symbol: '€' },
    'MT': { name: 'Malta', code: 'EUR', symbol: '€' },
    'LU': { name: 'Luxembourg', code: 'EUR', symbol: '€' },
    'CY': { name: 'Cyprus', code: 'EUR', symbol: '€' },
    'BG': { name: 'Bulgaria', code: 'BGN', symbol: 'лв' },
    'HR': { name: 'Croatia', code: 'HRK', symbol: 'kn' },
    'EE': { name: 'Estonia', code: 'EUR', symbol: '€' },
    'RS': { name: 'Serbia', code: 'RSD', symbol: 'дин' },
    'BY': { name: 'Belarus', code: 'BYN', symbol: 'Br' },
    'AM': { name: 'Armenia', code: 'AMD', symbol: '֏' },
    'GE': { name: 'Georgia', code: 'GEL', symbol: '₾' },
    'KZ': { name: 'Kazakhstan', code: 'KZT', symbol: '₸' },
    'AZ': { name: 'Azerbaijan', code: 'AZN', symbol: '₼' },
    'LB': { name: 'Lebanon', code: 'LBP', symbol: 'ل.ل' },
    'OM': { name: 'Oman', code: 'OMR', symbol: 'ر.ع.' },
    'BH': { name: 'Bahrain', code: 'BHD', symbol: '.د.ب' },
    'TT': { name: 'Trinidad and Tobago', code: 'TTD', symbol: 'TT$' },
    'DO': { name: 'Dominican Republic', code: 'DOP', symbol: 'RD$' },
    'JM': { name: 'Jamaica', code: 'JMD', symbol: 'J$' },
    'PA': { name: 'Panama', code: 'PAB', symbol: 'B/.' },
    'CR': { name: 'Costa Rica', code: 'CRC', symbol: '₡' },
    'GT': { name: 'Guatemala', code: 'GTQ', symbol: 'Q' },
    'HN': { name: 'Honduras', code: 'HNL', symbol: 'L' },
    'SV': { name: 'El Salvador', code: 'SVC', symbol: '$' },
    'NI': { name: 'Nicaragua', code: 'NIO', symbol: 'C$' },
    'PR': { name: 'Puerto Rico', code: 'USD', symbol: '$' },
    'BO': { name: 'Bolivia', code: 'BOB', symbol: 'Bs.' },
    'PY': { name: 'Paraguay', code: 'PYG', symbol: '₲' },
    'EC': { name: 'Ecuador', code: 'USD', symbol: '$' },
    'GY': { name: 'Guyana', code: 'GYD', symbol: '$' },
    'SR': { name: 'Suriname', code: 'SRD', symbol: '$' },
    'BB': { name: 'Barbados', code: 'BBD', symbol: 'Bds$' },
    'TT': { name: 'Trinidad and Tobago', code: 'TTD', symbol: 'TT$' },
    'BM': { name: 'Bermuda', code: 'BMD', symbol: '$' },
    'GH': { name: 'Ghana', code: 'GHS', symbol: 'GH₵' },
    'UG': { name: 'Uganda', code: 'UGX', symbol: 'USh' },
    'DZ': { name: 'Algeria', code: 'DZD', symbol: 'د.ج' },
    'MA': { name: 'Morocco', code: 'MAD', symbol: 'د.م.' },
    'TN': { name: 'Tunisia', code: 'TND', symbol: 'د.ت' },
    'MD': { name: 'Moldova', code: 'MDL', symbol: 'L' },
    'MK': { name: 'North Macedonia', code: 'MKD', symbol: 'ден' },
    'BA': { name: 'Bosnia and Herzegovina', code: 'BAM', symbol: 'KM' },
    'AF': { name: 'Afghanistan', code: 'AFN', symbol: '؋' },
    'BD': { name: 'Bangladesh', code: 'BDT', symbol: '৳' },
    'BT': { name: 'Bhutan', code: 'BTN', symbol: 'Nu.' },
};

export default countryToCurrency;