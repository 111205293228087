import React from 'react'
import styled from 'styled-components';


const NavbarSpacer = styled.div`
  height: 90px;
  width: 100%;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const Wrapper = styled.div`
    padding: 50px 250px;
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 20px 15px;
    }

    @media (max-width: 992px) and (min-width: 769px) {
        padding: 30px 50px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 22px;
    letter-spacing: 3px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 30px;
    }
`;

const SubTitle = styled.div`
   font-size: 16px;
   font-weight: 800;
   margin-bottom: 20px;
   letter-spacing: 1.5px;
   padding-top: 40px;

    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 15px;
        padding-top: 30px;
    }
`;

const Description = styled.div`
     p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: #4d4d4d;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;


const Returns = () => {
  return (
    
        

        <Wrapper>
        <NavbarSpacer/>
    <Title>RETURNS</Title>
    
    <SubTitle>RETURN POLICY</SubTitle>
    <Description>
        <p>A request for an exchange must be submitted within 14 days of receiving your order.</p>
        
        <p>The item(s) must be in original condition with the original packaging and all tags attached. Any garments that appear to have been worn, washed, or altered in any way will be refused for any exchanges. Please take care when trying on garments, as we cannot accept garments that have incurred any makeup stains or that smell of perfume. The item(s) must be returned to our address before the refund or exchange can be issued. The ability to fulfill exchange requests will be subject to the availability of garments at the time at which the return is received for processing.</p>
        
        <p>Only authorised returns will be processed. Any returns sent without authorisation will be refused and it will be the responsibility of the customer to arrange collection of their garment.</p>
    </Description>

    <SubTitle>ORGANISE EXCHANGE </SubTitle>
    <Description>
        <p>If you've met the above requirements, please email us at blleuebelle@outlook.com to complete your exchange.</p>
        
        <p>We recommend using a registered postal service to return goods, and keeping a record of the tracking details, as we are unable to be held liable for the loss of your item being returned.</p>
    </Description>

    <SubTitle>PROCESS EXCHANGE </SubTitle>
    <Description>
        <p>Once we receive your return, please allow up to 5-7 business days for your return to be processed by our team. We will send you an email notification once your exchange has been shipped so you know it is on the way.</p>
        
        <p>Please note, exchanges are subject to availability at the time your return is received and processed by our team.</p>
    </Description>

    <SubTitle>RETURN SHIPPING </SubTitle>
    <Description>
        <p>The shipping cost of returning item(s) to Bleue Belle for an exchange is not covered or reimbursed by us.</p>
    </Description>
</Wrapper>






        
   
  )
}

export default Returns