import React, { useState } from 'react';
import styled from 'styled-components';

import ClothingImage from './Images/IMG_1570.jpg';
import RedDress from './Images/IMG_1967.jpg';



const Container = styled.div``;

const NavbarSpacer = styled.div`
  height: 100px;
  width: 100%;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const Wrapper = styled.div`
   padding: 80px;
  max-width: 1200px; 
  margin: 0 auto; 
  font-size: 15px;
  

  @media (max-width: 768px) {
    padding: 15px;
    margin-top: 60px;
  }
`;

const ScrollableContent = styled.div`
  max-height: 840px; 

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari and Opera
  }
  -ms-overflow-style: none;  // Hide scrollbar for IE and Edge
  scrollbar-width: none; // Hide scrollbar for Firefox

   

  @media (max-width: 768px) {
        max-height: 660px; // 2 x 250px
    }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
      width: 0;
      height: 0;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;


const Title = styled.h1`
  text-align: left;
  border-bottom: 1px solid black;
  font-size: 23px;
  padding-bottom: 15px;
  letter-spacing: 3px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 400px;
  width: 290px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 310px;
    width: 220px;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 25px;
  
  
`;

const ClothingName = styled.div`
   letter-spacing: 3px;

`;

const Price = styled.div`
letter-spacing: 1px;

`;

const Size = styled.div`



`;

const Quantity = styled.div`
display: flex;
  align-items: center;
`;

const QuantityInput = styled.input`
  width: 25px;
  height: 18px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
  color: #333;
  
  font-size: 16px;
  
`;

const Button = styled.button`
display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 22px;
  background-color: #f8f8f8;
  border-radius: 0px;
  border: 1px solid #ddd;
  color: #333;
  font-size: 18px;
  
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;


const Line = styled.hr`
  border-top: 1px solid black;
`;

const SubtotalSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;


const SubtotalText = styled.h2`
  font-size: 16px;
  letter-spacing: 3px;
`;

const SubtotalPrice = styled.h2`
  font-size: 16px;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
  right: 20px; 

  @media (max-width: 768px) {
    right: 10px; 
  }
`;

const CheckoutButton = styled.button`
  width: 500px;
  padding: 15px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
  height: 50px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 3px;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SubtotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
`;


const Cart = () => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <Container>
      <NavbarSpacer/>

      <Wrapper>

        <Title>CART</Title>

        <ScrollableContent>
        <ProductDetails>
          <Image src={ClothingImage} alt='Clothing' />
          <DetailsWrapper>
            <ClothingName>BLACK DRESS</ClothingName>
            <Price>£100.00</Price>
            <Size>M</Size>
            <Quantity>
              <Button onClick={handleDecrease}>-</Button>
              <QuantityInput type='text' value={quantity} readOnly />
              <Button onClick={handleIncrease}>+</Button>
            </Quantity>
          </DetailsWrapper>
        </ProductDetails>
        

      
        <ProductDetails>
          <Image src={RedDress} alt='Clothing' />
          <DetailsWrapper>
            <ClothingName>RED DRESS</ClothingName>
            <Price>£150.00</Price>
            <Size>L</Size>
            <Quantity>
              <Button onClick={handleDecrease}>-</Button>
              <QuantityInput type='text' value={quantity} readOnly />
              <Button onClick={handleIncrease}>+</Button>
            </Quantity>
          </DetailsWrapper>
        </ProductDetails>
       

     
        <ProductDetails>
          <Image src={RedDress} alt='Clothing' />
          <DetailsWrapper>
            <ClothingName>RED DRESS</ClothingName>
            <Price>£150.00</Price>
            <Size>L</Size>
            <Quantity>
              <Button onClick={handleDecrease}>-</Button>
              <QuantityInput type='text' value={quantity} readOnly />
              <Button onClick={handleIncrease}>+</Button>
            </Quantity>
          </DetailsWrapper>
        </ProductDetails>
       


     
        <ProductDetails>
          <Image src={RedDress} alt='Clothing' />
          <DetailsWrapper>
            <ClothingName>RED DRESS</ClothingName>
            <Price>£150.00</Price>
            <Size>L</Size>
            <Quantity>
              <Button onClick={handleDecrease}>-</Button>
              <QuantityInput type='text' value={quantity} readOnly />
              <Button onClick={handleIncrease}>+</Button>
            </Quantity>
          </DetailsWrapper>
        </ProductDetails>
        </ScrollableContent>
        <Line />

        <SubtotalSection>
        <SubtotalContainer>
        
          <SubtotalText>SUBTOTAL</SubtotalText>
          <SubtotalPrice>£250.00</SubtotalPrice>
          
          </SubtotalContainer>
          
          <CheckoutButton>CHECK OUT</CheckoutButton>
        </SubtotalSection>

        
        

      </Wrapper>

      
    </Container>
  );
};

export default Cart;
