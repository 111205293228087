import React, { useState, useContext, useEffect } from 'react';
import Home from "./Home";
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Contact from "./Contact";
import SizeGuide from "./SizeGuide";
import Returns from "./Returns";
import Cart from "./Cart";
import Shipping from "./Shipping";
import Login from "./Login";
import Register from "./Register";
import StorePage from "./Store";
import Footer from "./Components/Footer";
import SinglePgTest from "./SinglePgTest";
import { CartProvider } from './Contexts/CartContext';
import Success from "./Components/success";
import CurrencyContext from './Contexts/CurrencyContext';
import AboutUs from './AboutUs';

function App() {
  const [conversionRate, setConversionRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState('€');
  const [currencyCode, setCurrencyCode] = useState('EUR');
  const [searchTerm, setSearchTerm] = useState("");

  const location = useLocation();

useEffect(() => {
    window.scrollTo(0, 0);
}, [location.pathname]);
  
  return (
    <CartProvider>
      <CurrencyContext.Provider value={{ conversionRate, currencySymbol, currencyCode, setConversionRate, setCurrencySymbol, setCurrencyCode }}>
        <div className="App">
          <Navbar setSearchTerm={setSearchTerm} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/:name/:id" element={<SinglePgTest />} />
            <Route path="/store" element={<StorePage searchTerm={searchTerm} />} />
            <Route path="/size" element={<SizeGuide/>} />
            <Route path="/returns" element={<Returns />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/sizeguide" element={<SizeGuide />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/success" element={<Success />} />
            <Route path="/aboutus" element={<AboutUs />} />
          </Routes>
          <Footer />
        </div>
      </CurrencyContext.Provider>
    </CartProvider>
  );
}

export default App;
