import { useState } from 'react';
import styled from 'styled-components';

const CardPrice = styled.p`
  font-size: 1em;
  letter-spacing: 1.0px;
  margin: 0;
  position: absolute;
  bottom: 2px;
  right: 10px;
  padding: 5px;
  @media (max-width: 768px) {
    font-size: 0.9em;
    }
`;

const CardTitle = styled.p`
  font-size: 1rem;
  margin: 0;
  letter-spacing: 1.0px;
  position: absolute;
  bottom: 2px;
  left: 10px;
  padding: 5px;
  @media (max-width: 768px) {
    font-size: 0.9em;
    }
`;

const StyledCard = styled.div`
  width: 100%;
  height: 45vw;
  min-height: 300px;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  text-decoration: none;
  overflow: hidden;
  color: black;
  position: relative;
    @media (max-width: 1000px) {
    width: 50vw;
    height: 70vw;
    }
    @media (max-width: 768px) {
    width: 100vw;
    height: 130vw;
    min-height: 200px;
    }

`;


const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
`;

const LargeCard = ({ src1, src2, title, price }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCard onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <CardImageContainer>
        <CardImage src={src1} isVisible={!isHovered} />
        <CardImage src={src2} isVisible={isHovered} />
      </CardImageContainer>
      <CardTitle>{title}</CardTitle>
      <CardPrice>{price}</CardPrice>
    </StyledCard>
  );
};

export default LargeCard;