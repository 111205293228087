import React from 'react'
import Header from './Components/Header'
import Categories from './Components/Categories'

const Home = () => {
  return (
    <div>
      <Header/>
      <Categories/>
    </div>
  )
}

export default Home
