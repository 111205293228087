import React from 'react';

const InstagramFeed = () => (
  <iframe 
    src="https://cdn.lightwidget.com/widgets/79e92074455a5619a3b3314ad710e305.html" 
    scrolling="no" 
    allowTransparency="true" 
    className="lightwidget-widget" 
    style={{ width: '100%', border: '0', overflow: 'hidden' }}
  ></iframe>
);

export default InstagramFeed;
