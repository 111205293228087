import React from 'react'

import styled from 'styled-components';



const Container = styled.div`
    
`

const NavbarSpacer = styled.div`

  height: 80px;
  width: 100%;
`;



const Wrapper = styled.div`
    padding: 50px 20px; 
    
    max-width: 1200px;
    margin: 0 auto;

    @media (min-width: 768px) { 
        padding: 80px 80px;
        
    }

    @media (min-width: 1024px) {
        padding: 80px 250px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    letter-spacing: 2px;
    margin-bottom: 30px;

    @media (min-width: 768px) { 
        font-size: 20px;

    }

    @media (min-width: 1024px) { 
        font-size: 22px;
        margin-bottom: 50px;
    }
`;

const SubTitle = styled.div`
    font-size: 14px; 
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
    text-align: center;

    @media (min-width: 768px) { 
        font-size: 15px;
        margin-top: 60px;
    }
`;

const Description = styled.div`
     p {
        margin-top: 1rem;   
        margin-bottom: 1rem;
    }
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: #4d4d4d;
    text-align: left;
    

`

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 90px;
    text-align: center;
`;

const TableHeader = styled.th`
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #f2f2f2;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
`;

const TableCell = styled.td`
    border: 1px solid #ddd;
    padding: 8px;
`;


const DescriptionTitle= styled.div`
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
    padding-top: 40px;
    


`

const Shipping = () => {
  return (
    <Container>
        <NavbarSpacer />

    
    <Wrapper>
    <Description>
        <Title>SHIPPING & EXCHANGES</Title>
        


        <SubTitle>IRELAND</SubTitle>
    <Table>
        <thead>
            <TableRow>
                <TableHeader>Type of Order</TableHeader>
                <TableHeader>Processing Time</TableHeader>
                <TableHeader>Ireland</TableHeader>
            </TableRow>
        </thead>
        <tbody>
            <TableRow>
                <TableCell>Regular</TableCell>
                <TableCell>3-5 business days</TableCell>
                <TableCell>1-3 working days</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Pre-order</TableCell>
                <TableCell>Up to 2-3 weeks (5-6 weeks off-peak)</TableCell>
                <TableCell>1-3 working days</TableCell>
            </TableRow>
        </tbody>
    </Table>

    <SubTitle>INTERNATIONAL</SubTitle>
    <Table>
        <thead>
            <TableRow>
                <TableHeader>Type of Order</TableHeader>
                <TableHeader>Processing Time</TableHeader>
                <TableHeader>International</TableHeader>
            </TableRow>
        </thead>
        <tbody>
            <TableRow>
                <TableCell>Regular</TableCell>
                <TableCell>3-5 business days</TableCell>
                <TableCell>3-5 working days</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Pre-order</TableCell>
                <TableCell>Up to 2-3 weeks (5-6 weeks off-peak)</TableCell>
                <TableCell>3-5 working days</TableCell>
            </TableRow>
        </tbody>
    </Table>






    

    <DescriptionTitle>SALES ITEMS:</DescriptionTitle>
    <p>Discounted items are not eligible for exchanges and refunds.</p>

    <DescriptionTitle>PROMOTIONAL PERIODS:</DescriptionTitle>
    <p>Items purchased using a discount or promotional code during a flash sale are Non- Refundable and not eligible for Exchanges.</p>

    <DescriptionTitle>EXCHANGES & STORE CREDITS:</DescriptionTitle>
    <p>If you are unsatisfied with your order, have received faulty merchandise or need to make an exchange please email us at blleuebelle@outlook.com with your order number in the subject line. We offer exchanges and store credit only within 14 days of receiving your purchase. We do not process refunds. No exceptions. Any returns sent without authorisation will be refused and it will be the responsibility of the customer to arrange collection of their garment. We reserve the right to cancel orders in which case a full refund will be issued.</p>

    <DescriptionTitle>HOW TO EXCHANGE:</DescriptionTitle>
    <p>Exchanged items can be shipped to this address:</p>

    <p>
    House Number 20<br />
    Little Commons<br />
    Lusk<br />
    Co. Dublin<br />
    Ireland<br />
    K45 P030</p>


    <DescriptionTitle>CONDITIONS</DescriptionTitle>
    <p>1. We are not responsible for the shipping cost for these items. You can send it back with carrier of your choice as long as there is proof of a receipt or tracking number.</p>

    <p>2. Please send an e-mail to blleuebelle@outlook.com with your order number, name, and address together with the tracking number/ receipt. We would really appreciate if you could tell us the reason for your return or exchange.</p>

    <p>Once we receive your return, please allow approximately 7-10 business days for your return to be processed by our team. We’ll send you an email notification once your exchange has been shipped so you know it’s on the way. Please note, exchanges are subject to availability at the time your return is received and processed by our team. If you require a change of address for your exchange postage, you will need to contact us at blleuebelle@outlook.com and await our confirmation.</p>

    <DescriptionTitle>DUTIES & CUSTOMS:</DescriptionTitle>
    <p>In most cases, any customs or import duties are charged once the parcel reaches its destination country. The exception to this is for parcels being sent to the Republic of Ireland and Northern Ireland.</p>

    <p>Although, you may be charged for handling fees and taxes as your order passes through customs. Any charges on a parcel must be paid by the person receiving the parcel (this also applies to retail & wholesale customers).</p>

    <p>Bleue Belle has no control over these charges and we can't tell you what the cost would be, as customs policies and import duties vary widely from country to country.</p>

    <p>It might be a good idea to contact your local customs office for current charges before you order, so you are not surprised by charges you were not expecting.</p>
</Description>


</Wrapper>
    
    </Container>
  )
}

export default Shipping