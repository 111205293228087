import React from 'react'
import styled from 'styled-components';

const Container = styled.div``;

const NavbarSpacer = styled.div`
  height: 90px;
  width: 100%;
  @media (max-width: 768px) {
    height: 45px;
  }
`;

const Wrapper = styled.div`
    padding: 50px 20px; 
    max-width: 1200px;
    margin: 0 auto;

    @media (min-width: 768px) { 
        padding: 80px 80px;
    }

    @media (min-width: 1024px) {
        padding: 80px 250px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 23px;
    letter-spacing: 2px;
    margin-bottom: 30px;

    @media (max-width: 768px) { 
        font-size: 20px;
    }

    @media (min-width: 1024px) { 
        font-size: 20px;
        margin-bottom: 50px;
    }
`;

const SectionTitle = styled.div`
   
   font-size: 16px;
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
    padding-top: 40px;
    text-align: center;
    text-transform: uppercase;
    


`;

const Description = styled.div`
     p {
        margin-top: 1rem;   
        margin-bottom: 1rem;
        text-align: center;
    }
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: #4d4d4d;
`

const AboutUs = () => {
  return (
    <Container>
      <NavbarSpacer />
      <Wrapper>
        <Title>ABOUT US</Title>
        <Description>
          <p>
            Bleue Belle, pronounced {`{BLUE-BELL}`} was created in 2020, founded by Ana Carolina Cazacu. Devoted to designing pieces that create memories, the brand evokes beauty and empowers the women of our world. This concept is the very core of Bleue Belle, celebrating self-expression, confidence, and femininity.
          </p>

          <SectionTitle>Our Philosophy</SectionTitle>
          <p>
            Bleue Belle is a slow fashion non-seasonal, ready-to-wear brand born out of my love for perfecting one-off pieces, all with the same core intention - to embody the confident, independent wearer to feel good.
          </p>

        
      

<SectionTitle>Sustainability & Ethics</SectionTitle>
<p>
  Our brand mission is to create quality garments with a timeless appeal. While we hope to give each and every one of you a chance to wear Bleue Belle, we’re mindful of the quantities we order. For a world moving so rapidly and producing more each day, we recognize the need to find balance between production and conservation. At Bleue Belle, our brand’s beliefs encapsulate the notions of circular fashion and authentic designs inspired by women worldwide.
</p>
<p>
  As a slow fashion label, we emphasize low quantities and high quality, aiming to reduce waste and promote longevity in each piece. Designing intentionally ensures that each garment aligns with our exacting standards of quality and fit. While the journey to a completely sustainable and ethical world is long, we are committed to playing our part in making a difference.
</p>







          <SectionTitle>Transparency in Manufacturing</SectionTitle>
          <p>
            We are always transparent with our customers, which is why we work very closely with our beautiful manufacturer. Currently Bleue Belle manufactures in Japan! All our pieces are handcrafted in Japan by a team of embroiderers, beaders and sewers.
          </p>

          <p>
            With love, Ana x
          </p>
        </Description>
      </Wrapper>
    </Container>
  )
}

export default AboutUs;
