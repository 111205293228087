import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.div`
    bottom: 0;
    margin-top: 5rem;
    width: 60%;
    background-color: 'white';
    color: 'black';
    padding: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-top: 1.5px dotted #999;
    @media (max-width: 768px) {
    width: 70%;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 20px;
    }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: space-around;
  white-space: nowrap;
  letter-spacing: 1PX;

`;

const FooterLink = styled(Link)`
  color: #000;
  margin: 0 1rem;
  text-decoration: none;
  @media (max-width: 768px) {
    font-size: 0.8em;
}

`;

const FooterText = styled.div`
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 3px;
    margin-bottom: 15px;
    text-align: center;
    @media (max-width: 768px) {
    font-size: 1.2em;
    }
`;


const Footer = () => (
  <FooterContainer>
    <FooterText>BLEUE BELLE</FooterText>
    <FooterLinks>
    <FooterLink to="/contact">CONTACT</FooterLink>
      <FooterLink to="/returns">RETURNS</FooterLink>
      <FooterLink to="/size">SIZE GIDE</FooterLink>
      <FooterLink to="/shipping">SHIPPING</FooterLink>
    </FooterLinks>
  </FooterContainer>
);

export default Footer;