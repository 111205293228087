import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { FiSearch, FiShoppingCart, FiGlobe } from 'react-icons/fi';
import { MdLocationOn } from "react-icons/md";
import { useNavigate, Link } from 'react-router-dom';
import SideCart from './SideCart';
import countryToCurrency from '../Contexts/Currency';
import CurrencyContext from '../Contexts/CurrencyContext';


<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&family=Space+Grotesk:wght@300&display=swap" rel="stylesheet"></link>

const NavbarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 3rem;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.isScrolled || props.isOpen ? 'white' : 'transparent')};
  color: ${(props) => (props.isScrolled ? 'black' : 'black')};
  transition: background-color 0.3s;
  z-index: 768;

  @media (max-width: 768px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const NameContainer = styled(Link)`
  display: flex;
  text-align: center;
  font-size: 2rem;
  margin-left: 40px;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 3px;
  flex-direction: row-reverse;
  text-decoration: none;  
  color: inherit;  
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1200px) {
    margin-left: 0;
    display: inline;
    flex-direction: row;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.3rem;
  }
  order: 0;
`;


const NavLinksContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  @media (max-width: 1200px) {
    display: none; // hide on mobile
  }
  flex-direction: row;
  @media (max-width: 1200px) {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  }
`;
const NavLink = styled(Link)`
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 2rem;
  cursor: pointer;
  text-decoration: none;
  color: black;
  letter-spacing: 1px;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 5px; // adjust this value to move the underline closer or farther from the text
    left: 0;
    background-color: black;
    transition: width 0.3s;
  }
  &:hover::after {
    width: 100%;
  }
  @media (max-width: 1200px) {
    margin: 1rem;
  }
`;



const HamburgerMenu = styled.div`
  font-size: 1.4em;
  display: none;
  order: 1;
  margin-left: 20px;

  @media (max-width: 1200px) {
    order: 0;
  }
  @media (max-width: 1200px) {
    display: block;
  }
`;

const RightHandSideContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end; 
  margin-top: .5rem;
  @media (max-width: 1200px) {
    text-align: center;
  }
  padding-right: 5rem;
  order: 0;
  @media (max-width: 1200px) {
    order: 2;
    padding-right: 0;
  }
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  width: 30rem;
  @media (max-width: 1200px) {
    width: auto;
    justify-content: flex-end;
    & > :not(:last-child) {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    width: auto;
    font-size: 1.5;
    padding-right: 30px;
    padding-bottom: 10px;
    justify-content: flex-end;
    & > :not(:last-child) {
      display: none;
    }
  }
`;

const SearchInput = styled.input`
  padding: 0.5rem;
  border: none;
  height: 1rem;
  border-radius: 0;
  border-bottom: 1px solid black;
  opacity: 0;
  cursor: default;
  outline: none;
  background: transparent;
  font-size: 1rem;
  width: 15rem;
  @media (max-width: 1200px) {
    border-bottom: none;
    opacity: 1;
  }
  ${props => props.iconClicked && `
    opacity: 1;
    cursor: text;
  `}

  
  &:focus::placeholder {
    color: transparent;
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 57px;
  width: 100%;
  background-color: white;
  z-index: 1000;
  max-height: ${(props) => (props.isOpen ? '480px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease-in;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const DropdownLink = styled(Link)`
  position: relative;
  padding: 1rem;
  padding-left: 2.5rem;
  font-size: 1.2rem;
  color: black;
  border-bottom: 1px solid #e0e0e0;
  
  text-decoration: none;
`;
const LocationDropdownStyled = styled.div`
  position: relative;
  padding: 1rem;
  margin-left: 0.2rem;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;

const SearchBarStyled = styled.div`
  position: relative;
  padding: 1rem;
  margin-left: 0.3rem;
  font-size: 1.2rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
`;


const CountryModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const CountryModalContent = styled.div`
  background: white;
  padding: 20px;
  width: 50%;
  max-width: 500px;
  border-radius: 8px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const CountryList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
`;

const CountryListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const CountryBox = styled.div`
  display: flex;
  align-items: center;
`;

const CountryCode = styled.span`
  font-size: 0.8rem;  // Adjust as needed
  margin-top: 5px;   // Adjust as needed
  margin-left: 5px;   // Adjust the gap between the icon and the code
`;

const SearchIcon = styled(FiSearch)`
  cursor: pointer;
`;

const LocationIconContainer = styled(CountryBox)`
  cursor: pointer;
`;

const LocationIcon = styled(MdLocationOn)`
  cursor: pointer;
`;

const ShoppingCartIcon = styled(FiShoppingCart)`
  cursor: pointer;
`;




const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSideCartOpen, setIsSideCartOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const [isIconClicked, setIsIconClicked] = useState(false);

  const dropdownRef = useRef(null);
  const [countryModal, setCountryModal] = useState(false);
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState('');
  const { setConversionRate, setCurrencySymbol, setCurrencyCode } = useContext(CurrencyContext);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/store?search=${localSearchTerm}`);
    closeDropdown();
  }


  const countries = Object.entries(countryToCurrency).map(([key, value]) => ({
    abbreviation: key,
    ...value,
  }));

  const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    handleInitialCountrySetup();
    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const handleInitialCountrySetup = async () => {
    const storedCountry = localStorage.getItem('selectedCountry');
    const country = storedCountry ? storedCountry : await getCountryFromIP();
    updateCountryData(country);
  };

  const getCountryFromIP = async () => {
    try {
      const response = await fetch('https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/ip-to-country');
      const data = await response.json();
      return data.country;
    } catch (error) {
      console.error("Error fetching the country:", error.message);
      return null;
    }
  };

  const updateCountryData = (countryAbbreviation) => {
    setSelectedCountry(countryAbbreviation);
    const currencyInfo = countryToCurrency[countryAbbreviation];
    if (currencyInfo) {
      setCurrencySymbol(currencyInfo.symbol);
      setCurrencyCode(currencyInfo.code);
      fetchAndSetConversionRate(currencyInfo.code);
    }
  };

  const fetchAndSetConversionRate = async (currencyCode) => {
    try {
      const response = await fetch(`https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/rate/${currencyCode}`);
      const data = await response.json();
      setConversionRate(data.rate);
    } catch (error) {
      console.error("Error fetching the conversion rate:", error.message);
    }
  };

  const handleCartClose = () => {
    setIsSideCartOpen(false);
  }

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleCountrySelect = (country) => {
    const abbreviation = country.abbreviation;
    setSelectedCountry(country);
    setCountryModal(false);
    localStorage.setItem('selectedCountry', abbreviation);
    updateCountryData(abbreviation);
  };

  const addEventListeners = () => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
  };

  const removeEventListeners = () => {
    window.removeEventListener('scroll', handleScroll);
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 10);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };



  return (
    <>
      <NavbarContainer ref={dropdownRef} isScrolled={isScrolled} isOpen={isOpen}>
        <HamburgerMenu onClick={() => setIsOpen(!isOpen)}>
          ☰
        </HamburgerMenu>
        <NameContainer to="/" onClick={closeDropdown}>
          BLEUE BELLE
        </NameContainer>
        <NavLinksContainer>
          <NavLink to="/">HOME</NavLink>
          <NavLink to="/store">STORE</NavLink>
          <NavLink to="/aboutus">INFO</NavLink>
        </NavLinksContainer>
        <RightHandSideContainer>
          <IconBox>
            <form onSubmit={handleSearch}>
              <SearchInput
                type="text"
                value={localSearchTerm}
                onChange={(e) => setLocalSearchTerm(e.target.value)}
                placeholder="Search for products..."
                onSubmit={handleSearch}
                iconClicked={isIconClicked}  // pass the state as a prop
              />
            </form>
            <SearchIcon onClick={() => setIsIconClicked(!isIconClicked)} />
            <LocationIconContainer onClick={() => {
              setCountryModal(true);
            }}>
              <LocationIcon />
              {selectedCountry ? <CountryCode>{countryToCurrency[selectedCountry]?.name || ''}</CountryCode> : null}
            </LocationIconContainer>
            <ShoppingCartIcon onClick={() => setIsSideCartOpen(!isSideCartOpen)} />
          </IconBox>
        </RightHandSideContainer>
        <SideCart isOpen={isSideCartOpen} onClose={handleCartClose} />
        <DropdownMenu isOpen={isOpen} isScrolled={isScrolled}>
          <SearchBarStyled onClick={() => console.log("Among Us")}>
            <FiSearch />
            <span style={{ marginLeft: '1rem' }}></span>
            <form onSubmit={handleSearch}>
              <SearchInput type="text"
                value={localSearchTerm}
                onChange={(e) => setLocalSearchTerm(e.target.value)}
                placeholder="Search for products..."
                onSubmit={handleSearch} />
            </form>
          </SearchBarStyled>
          <DropdownLink onClick={closeDropdown} to="/">Home</DropdownLink>
          <DropdownLink onClick={closeDropdown} to="/store">Store</DropdownLink>
          <DropdownLink onClick={closeDropdown} to="/aboutus">Info</DropdownLink>
          <DropdownLink onClick={closeDropdown} to="/contact">Contact</DropdownLink>
          <LocationDropdownStyled onClick={() => setCountryModal(true)}>
            <MdLocationOn />
            {selectedCountry ? <span style={{ marginLeft: '5px' }}>{countryToCurrency[selectedCountry]?.name || ''}</span> : null}
          </LocationDropdownStyled>

        </DropdownMenu>
      </NavbarContainer>
      {
        countryModal && (
          <CountryModal onClick={() => setCountryModal(false)}>
            <CountryModalContent onClick={e => e.stopPropagation()}>
              <h2>Select your country</h2>
              <CountryList>
                {sortedCountries.map(country => (
                  <CountryListItem key={country.abbreviation} onClick={() => handleCountrySelect(country)}>
                    {country.name}
                  </CountryListItem>
                ))}
              </CountryList>
            </CountryModalContent>
          </CountryModal>
        )
      }
    </>

  );
};

export default Navbar;