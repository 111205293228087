// Contact.jsx is a component that is rendered by the router in App.js.

import { useState } from 'react';

import styled from 'styled-components';


const Container = styled.div``;

const NavbarSpacer = styled.div`
  height: 90px;
  width: 100%;
`;

const Wrapper = styled.div`
    padding: 50px 250px;
    height: 800px;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-height: 800px) {
        height: 100vh;
    }

    @media (max-width: 768px) {
        padding: 80px 15px;
    }

    @media (max-width: 992px) and (min-width: 769px) {
        padding: 80px 50px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 22px;
    letter-spacing: 3px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 30px;
    }
`;

const Description = styled.div`
    p {
        margin-top: 0.1rem;
        margin-bottom: 0.1em;
    }
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-align: center;
    color: #676767;
`;

const Email = styled.div`
    padding-top: 25px;
    color: #676767;
`;

// Contact Form Styles
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    color: #676767;
    font-size: 14px;
`;

const InputRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const InputBox = styled.div`
    flex: 1;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 15px;
    }
`;

const MessageBox = styled.div`
    margin-top: 20px;

    label {
        display: block;
        margin-bottom: 5px;
    }

    textarea {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        resize: vertical;
    }
`;

const SendButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 15px;

    &:hover {
        background-color: #333;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;
const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [feedback, setFeedback] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);



    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
    
        // Initiate reCAPTCHA verification
        grecaptcha.ready(async function() {
            try {
                const token = await grecaptcha.execute('6LcOnqQoAAAAAPH0hdrGF1fK1wgiiVu5q9XN51kF', {action: 'submit'});
    
                // Now, include this token in your request to the server.
                const response = await fetch('http://localhost:8010/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...formData,
                        recaptchaToken: token  // This is the reCAPTCHA response token
                    })
                });
    
                const data = await response.json();
                if (data.success) {
                    setFeedback("Email sent successfully!");
                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    });
                } else {
                    setFeedback(data.message || "Failed to send email.");
                }
            } catch (error) {
                setFeedback("An error occurred. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        });
    };
    
    
    return (
        <div>
            <NavbarSpacer />
        <Container>
            <Wrapper>
                <Title>CONTACT</Title>
                <Description>
                    <p>General Inquiries</p>
                    <p>Customer Service Questions</p>
                    <p>Press, Wholesale and Job Inquiries</p>
                    <Email>blleuebelle@outlook.com</Email>
                </Description>
               
                <FormContainer onSubmit={handleSubmit}>
                    <InputRow>
                    <InputBox>
                    <label htmlFor="name">NAME</label>
                    <input 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                </InputBox>
                <InputBox>
                    <label htmlFor="email">EMAIL</label>
                    <input 
                        type="email" 
                        id="email" 
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </InputBox>
                    </InputRow>
                    <MessageBox>
                <label htmlFor="message">MESSAGE</label>
                <textarea 
                    id="message" 
                    name="message" 
                    rows="5"
                    value={formData.message}
                    onChange={handleInputChange}
                ></textarea>
            </MessageBox>
            <SendButton type="submit" disabled={isLoading}>
    {isLoading ? "Sending..." : "Send"}
</SendButton>

{feedback && <div style={{ marginTop: '20px', color: '#000000', fontSize: "15px"}}>{feedback}</div>}

                </FormContainer>
            </Wrapper>
        </Container>
        </div>
    );
}

export default Contact;