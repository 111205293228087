import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const myImage = 'https://d2xhm5jlnom432.cloudfront.net/IMG_2007.jpg'
//const myMobileImage = 'https://d2xhm5jlnom432.cloudfront.net/IMG_1690.jpg'
const myMobileImage = 'https://d2xhm5jlnom432.cloudfront.net/IMG_1712.jpg'
//const myMobileImage = 'https://d2xhm5jlnom432.cloudfront.net/IMG_1694.jpg'


const Container = styled.div`
  
  position: relative;

  @media (max-width: 768px) {
    height: 100vh;
  }
`;


const MainImg = styled.img`

  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
  display: block;

  @media (max-width: 768px) {
    
    object-position:60% center;
  }
`;


const ElectraText = styled(Link)`
  font-size: 2rem;
  text-decoration: none;
  
  color: white;
  text-align: center;
  letter-spacing: 10px;

  @media (max-width: 768px) {
    font-size :20px ;
    letter-spacing: 3px;
    font-size: 0px;
  }

  @media (min-width: 1200px) {
    font-size: 3.5rem; 
  }
`;

const ShopNowText = styled(Link)`
  font-size: 1.0rem;
  color: white;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    font-size: 1rem; 
    font-size: 0px;
  }

  @media (min-width: 1200px) {
    font-size: 1.2rem; 
  }
`;

const TextOverlay = styled.a`
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 75%; 
    font-size: 0px;
  }
`;

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Update the state when the viewport size changes
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
        <MainImg src={isMobile ? myMobileImage : myImage} draggable="false" />
      <TextOverlay href="">
        <ElectraText to= "/product/Ivanna%20Dress/1">IVANNA</ElectraText>
        <ShopNowText to="/store">SHOP NOW</ShopNowText>
      </TextOverlay>
    </Container>
  );
}

export default Header;