import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // if you are using axios
import styled from 'styled-components';
import myImage from '../Images/IMG_1578.jpg';
import myImage2 from '../Images/IMG_1570.jpg';
import InstagramFeed from './InstagramFeed';
import { Link } from 'react-router-dom';
import Card from './Card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CurrencyContext from '../Contexts/CurrencyContext';

const Container = styled.div`
  padding: 50px 0;
  text-align: center;
  padding-bottom: 100px;

  @media (max-width: 768px) {
  padding: 0;
  }
`;

const SectionTitle = styled.div`
  font-size: 1.7rem;
  margin-bottom: 5px;
  letter-spacing: 3px;
  

  @media (max-width: 768px) {
  font-size: 20px;
  padding-top: 30px;
  
  }
`;

const SectionTitleLink = styled.a`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 20px;
  letter-spacing: 3px;
  text-decoration: none;
  color: inherit;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: currentColor;
    transition: width 0.3s, left 0.3s;
  }
  &:hover::after {
    width: 100%;
    left: 0;
  }

  @media (max-width: 768px) {
  font-size: 20px;
  }
`;

const ShopAllButton = styled.button`
    letter-spacing: 2px;
    padding: 4px 25px;
    font-size: 0.9rem;
    border: 1px solid black;   
    background-color: #fff;   
    color: #333;              
    cursor: pointer;
    margin-bottom: 30px;
    transition: background-color 0.3s, color 0.3s;
  

  &:hover {
    background-color: #e0e0e0; // Very light gray on hover
    color: #333;               
  }

  @media (max-width: 768px) {
  padding: 2px 15px;
  
  }
`;

const CardList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem auto;
  margin-bottom: 50px;
  padding: 0;
  list-style: none;

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
`;

const InstagramFeedBox = styled.div`
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 30px;
  }
`;

const Categories = () => {
  const [products, setProducts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { conversionRate, currencySymbol, currencyCode } = useContext(CurrencyContext);


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchRandomProducts = async () => {
      try {
        const response = await axios.get('https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/get-random-products/4');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching random products:', error);
      }
    };

    fetchRandomProducts();
  }, []);

  return (
    <Container>
      <SectionTitle>NEW COLLECTION</SectionTitle>
      <Link to="/store">
        <ShopAllButton>SHOP NOW</ShopAllButton>
      </Link>
      {isMobile ? (
  <Slider {...sliderSettings}>
    {products.map((product, index) => (
      <Link to={`/product/${encodeURIComponent(product.Name)}/${product.ID}`} key={index}>
        <Card src1={product.ImageLink} title={product.Name} price={`${currencySymbol}${(Number(Math.round(product.Price * conversionRate * 10) / 10).toFixed(2))}`} />
      </Link>
    ))}
  </Slider>
) : (
  <CardList>
    {products.map((product, index) => (
      <Link to={`/product/${encodeURIComponent(product.Name)}/${product.ID}`} key={index}>
        <Card src1={product.ImageLink} src2={product.ImageLink2} title={product.Name} price={`${currencySymbol}${(Number(Math.round(product.Price * conversionRate * 10) / 10).toFixed(2))}`}  />
      </Link>
    ))}
  </CardList>
)}
      
      <InstagramFeedBox>
      <SectionTitleLink href='https://www.instagram.com/blleuebelle/'>FOLLOW @BLLEUEBELLE</SectionTitleLink>
        <InstagramFeed />
      </InstagramFeedBox>
    </Container>
  );
}

export default Categories;

