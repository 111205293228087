import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { CartContext } from '../Contexts/CartContext';
import styled from "styled-components";


const Container = styled.div`
    height: 74vh;
`;

const NavbarSpacer = styled.div`
  height: 90px;
  width: 100%;
  @media (max-width: 768px) {
    height: 60px;
  }
`;


const SuccessContainer = styled(Container)`
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.h1`
    font-size: 2em;
    margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 1.2em;
    margin-bottom: 20px;
`;

const Link = styled.a`
    font-size: 1em;
    color: #007bff;
    margin: 10px 0;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

function SuccessPage(props) {
    const { clearCart } = useContext(CartContext);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const sessionId = new URLSearchParams(window.location.search).get('session_id');

        if(sessionId) {
            // Validate the session
            axios.get(`https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/validate-session/${sessionId}`, { cancelToken: source.token })
                .then(response => {
                    if (response.data.valid) {
                        // Clear the cart
                        clearCart();
                    }
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error("Error validating session:", error);
                        // Handle the error (maybe show a message to the user)
                    }
                });
        }

        // Cleanup function to cancel ongoing axios requests if the component is unmounted
        return () => {
            source.cancel();
        };
    }, []);  // Empty dependency array to ensure useEffect only runs once when the component mounts

    return (
        <SuccessContainer>
            <NavbarSpacer />
            <Title>Congratulations on Your Purchase!</Title>
            <Message>Thank you for shopping with us. You will receive an email confirmation shortly.</Message>
            
            <Message>If you have any questions, please <Link href="/contact">contact us</Link>.</Message>
        </SuccessContainer>
    );
}

export default SuccessPage;
