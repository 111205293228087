import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Card from './Components/Card';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LargeCard from './Components/LargeCard';
import CurrencyContext from './Contexts/CurrencyContext';





const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const NavbarSpacer = styled.div`
  height: 90px;
  width: 100%;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1; // This makes it grow and occupy the available space
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StoreContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 0;
  margin: 0;
  padding: 0;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;



const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const PageNumber = styled.button`
  margin: 0 5px;
  padding: 10px;
  border: none;
  background-color: #eee;
  cursor: pointer;
`;
const SectionTitle = styled.h2`
font-family: 'Noto Sans JP', sans-serif;
font-weight: 500;
  font-size: 2rem;
  margin-bottom: 20px;
  letter-spacing: 3px;
  text-align: center;
`;

const NoResultsMessage = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: 1.5rem;
  font-weight: bold;
`;

import { useSearchParams } from 'react-router-dom';

const StorePage = () => {
  const [products, setProducts] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;
  const { conversionRate, currencySymbol } = useContext(CurrencyContext);
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') || "";
  const [searchPhrase, setSearchPhrase] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/get-products'); // adjust the URL to point to your backend endpoint
        setProducts(response.data);

        if (searchTerm && (!response.data || !response.data.some(product => product.Name.toLowerCase().includes(searchTerm.toLowerCase())))) {
          setSearchPhrase("No search results found for " + searchTerm);
        } else {
          setSearchPhrase("");
        }

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [searchTerm]);

  const filteredProducts = products && searchTerm
    ? products.filter(product => product.Name.toLowerCase().includes(searchTerm.toLowerCase()))
    : products || [];


  const totalPages = products ? Math.ceil(filteredProducts.length / productsPerPage) : 0;


  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <PageNumber key={i} onClick={() => handleClick(i)}>
          {i}
        </PageNumber>
      );
    }
    return pageNumbers;
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  return (
    <div>
      <NavbarSpacer />
      <MainContainer>
        <PageContainer>
          <StoreContainer>
            {currentProducts.length > 0 ? (
              currentProducts.map((product, index) => {
                const adjustedPrice = Math.round(product.Price * conversionRate * 10) / 10;
                return (
                  <Link to={`/product/${encodeURIComponent(product.Name)}/${product.ID}`} key={index}>
                    <LargeCard
                      src1={product.ImageLink}
                      src2={product.ImageLink2}
                      title={product.Name}
                      price={`${currencySymbol}${adjustedPrice.toFixed(2)}`}
                    />
                  </Link>
                );
              })
            ) : (
              <NoResultsMessage>{searchPhrase}</NoResultsMessage>
            )}
          </StoreContainer>
        </PageContainer>
        <Pagination>{renderPageNumbers()}</Pagination>
      </MainContainer>
    </div>
  );
};

export default StorePage;
