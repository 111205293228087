import styled from "styled-components";
import React from "react";



const Container = styled.div`
  width: 100vw;
  height: 800px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 800px) {
    height: 100vh;
  }
`;

const NavbarSpacer = styled.div`

  height: 80px;
 
  
`;


const Wrapper = styled.div`
  width: 35%;
  padding: 20px;
  background-color: white;
  border-radius: 3px;

  @media (max-width: 1000px) {
    width: 60%; 
    padding: 15px; 
  }

  @media (max-width: 768px) {
    width: 90%; 
    padding: 15px; 
  }
`;





const Title = styled.h1`
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 3px;
  margin-bottom: 15px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px; 
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;

  @media (max-width: 768px) {
    margin: 8px 0; 
  }
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: black;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
  font-size: 12px;
  margin-top:  25px;

  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    padding: 12px 15px; 
  }
`;

const Link = styled.a`
  margin: 5px 0px;
  font-size: 13px;
  letter-spacing: 1px;
  cursor: pointer;
  vertical-align: baseline; 
  color: #676767;
`;


const Label = styled.label`
    display: block;
    margin-bottom: 1px;
    color: #676767;
    font-size: 13px;
    letter-spacing: 1px;
    margin-top: 20px;
`;


const Register = () => {
  return (
    <div>
      <NavbarSpacer />
      <Container>
        <Wrapper>
          <Title>REGISTER</Title>
          <Form>

          <Label htmlFor="FirstName">NAME</Label>
            <Input type="text" id="FirstName" name="FirstName" />
            
           
              <Label htmlFor="surname">LAST NAME</Label>
            
            <Input type="text" id="surname" name="surname" />



            <Label htmlFor="username">EMAIL</Label>
            <Input type="text" id="username" name="username" />
            
           
              <Label htmlFor="password">PASSWORD</Label>
            
            <Input type="password" id="password" name="password" />

           
            
            <Button>CREATE</Button>
            
          </Form>
        </Wrapper>
      </Container>
      </div>
    );
  };

export default Register