import React from 'react'

import styled from 'styled-components';

const NavbarSpacer = styled.div`
  height: 80px;
  width: 100%;
`;
const Container = styled.div``;

const Wrapper = styled.div`
    padding: 50px 250px;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 80px 15px;
    }

    @media (max-width: 992px) and (min-width: 769px) {
        padding: 80px 50px;
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 22px;
    letter-spacing: 3px;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 30px;
    }
`;

const SubTitle = styled.div`
    font-size: 16px;
    margin-top: 100px;
    margin-bottom: 30px;
    letter-spacing: 1.5px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 14px;
        margin-top: 50px;
    }
`;

const Description = styled.div`
     p {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    margin-bottom: 80px;
    letter-spacing: 1px;
    color: #4d4d4d;
    text-align:center;

    @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 50px;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 50px;
    text-align: center;
`;

const TableHeader = styled.th`
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #f2f2f2;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
`;

const TableCell = styled.td`
    border: 1px solid #ddd;
    padding: 8px;
`;


const SizeGuide = () => {
    return (
        <Container>
            <NavbarSpacer />

            <Wrapper>
                <Title>SIZE GUIDE</Title>
                <Description>
                    <p>Bleue Belle size guide is a general guideline for helping you find the size that would fit you best. Exact measurements may vary with each style and/or differ from the below guide.</p>

                    <p>Please note that the majority of our designs are adjustable, so they will adjust to your body if you are slightly above or below the measurements.</p>

                    <p>For any questions or queries on sizing, send us an email at blleuebelle@outlook.com.</p>
                </Description>



                <SubTitle>SIZE TABLE</SubTitle>
                <Table>

                    <thead>
                        <TableRow>
                            <TableHeader></TableHeader>
                            <TableHeader>BUST</TableHeader>
                            <TableHeader>WAIST</TableHeader>
                            <TableHeader>HIPS</TableHeader>
                        </TableRow>
                    </thead>
                    <tbody>
                        <TableRow>
                            <TableCell>XS</TableCell>
                            <TableCell>79cm / 31"</TableCell>
                            <TableCell>63cm / 25"</TableCell>
                            <TableCell>90cm / 35"</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>S</TableCell>
                            <TableCell>84cm / 33"</TableCell>
                            <TableCell>68cm / 26"</TableCell>
                            <TableCell>95cm / 37"</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>M</TableCell>
                            <TableCell>89cm / 35"</TableCell>
                            <TableCell>73cm / 28"</TableCell>
                            <TableCell>100cm / 39"</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>L</TableCell>
                            <TableCell>94cm / 37"</TableCell>
                            <TableCell>78cm / 30"</TableCell>
                            <TableCell>105cm / 41"</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>XL</TableCell>
                            <TableCell>99cm / 39"</TableCell>
                            <TableCell>83cm / 32"</TableCell>
                            <TableCell>110cm / 43"</TableCell>
                        </TableRow>
                    </tbody>
                </Table>

                <SubTitle>SIZE CONVERSION</SubTitle>
                <Table>
                    <thead>
                        <TableRow>
                            <TableHeader></TableHeader>
                            <TableHeader>EU</TableHeader>
                            <TableHeader>UK</TableHeader>
                            <TableHeader>USA</TableHeader>
                            <TableHeader>AUS</TableHeader>
                        </TableRow>
                    </thead>
                    <tbody>
                        <TableRow>
                            <TableCell>XS</TableCell>
                            <TableCell>34</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>6</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>S</TableCell>
                            <TableCell>36</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>4</TableCell>
                            <TableCell>8</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>M</TableCell>
                            <TableCell>38</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>10</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>L</TableCell>
                            <TableCell>40</TableCell>
                            <TableCell>12</TableCell>
                            <TableCell>8</TableCell>
                            <TableCell>12</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>XL</TableCell>
                            <TableCell>42</TableCell>
                            <TableCell>14</TableCell>
                            <TableCell>10</TableCell>
                            <TableCell>14</TableCell>
                        </TableRow>
                    </tbody>
                </Table>

            </Wrapper>




        </Container>
    )
}

export default SizeGuide