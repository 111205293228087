import React, { useState, useEffect, useContext } from "react";

import { CartContext } from "./Contexts/CartContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { MdKeyboardArrowDown } from "react-icons/md";
import { motion } from "framer-motion";
import CurrencyContext from "./Contexts/CurrencyContext";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import ClothingImage from "./Images/IMG_1967.jpg";
import ClothingImage2 from "./Images/IMG_1981.jpg";

const NavbarSpacer = styled.div`
  height: 90px;
  width: 100%;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  
  align-items: center;
  justify-content: center;
`;

const BoxGroup = styled.div`
  display: flex;
  max-width: 1300px;

  margin: 0 auto;

  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: first start; 
  padding: 10px;
  margin-right: 20px;
  max-width: calc(50% - 20px);

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  padding-right: 50px;
  padding: 10px;
  margin-left: 20px;
  max-width: calc(45% - 20px);

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 20px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
   
  }
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.1), transparent);
  }
`;

const LeftArrowContainer = styled(ArrowContainer)`
  left: 0;
  user-select: none;
  outline: none;
`;

const RightArrowContainer = styled(ArrowContainer)`
  right: 0;
  user-select: none;
  outline: none;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const Thumbnail = styled.img`
  width: 70px;
  height: 100px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 4px;
  border: ${(props) => (props.isSelected ? "1px solid black" : "none")};
`;

const InfoContainer = styled.div``;

const Title = styled.h1`
  margin-top: 30px;
  text-align: left;
  font-size: 19px;
  letter-spacing: 4px;

  @media (max-width: 768px) {
    margin: 0;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    margin: 0;
  }
`;

const Price = styled.div`
  text-align: left;

  letter-spacing: 3px;
  margin-bottom: 30px;
  font-size: 19px;
`;

const ColoursContainer = styled.div`
  margin-bottom: 30px;
`;

const ColoursLabel = styled.div`
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 7px;
`;

const ColourOfClothing = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 5px solid ${props => props.borderColor};
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;


const SizeContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SizeBoxContainer = styled.div`
  display: flex;
`;

const SizeBox = styled.div`
  width: 20px;
  height: 27px;
  text-align: center;
  line-height: 30px;
  padding: 3px 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 13px;

  border: 1px solid transparent;

  background-color: ${(props) => (props.isSelected ? "black" : "#f5f5f5")};
  color: ${(props) => (props.isSelected ? "white" : "black")};

  &:hover {
    background-color: ${(props) => (!props.isSelected ? "white" : "black")};
    border-color: ${(props) => (!props.isSelected ? "black" : "transparent")};
  }
`;

const SizeLabel = styled.div`
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 7px;
`;

const AddtoCart = styled.button`
  border: 1px solid black;
  padding: 8px;
  letter-spacing: 1.5px;
  text-align: center;
  flex: 1;
  font-size: 13px;
  color: white;
  background-color: black;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #333;
    
    cursor: pointer;
  }
`;

const CartContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Line = styled.hr`
  border: none;
  border-top: 1px solid black;
  color: none;
  background-color: none;
  height: 1px;
  width: 100%;
  margin-top: 50px;
  color: #4d4d4d;
  padding-bottom: 4px;
`;

const DescriptionHeader = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  cursor: pointer;
  border-bottom: 1px solid rgb(169, 169, 169);
  padding-bottom: 5px;
`;

const DescriptionContent = styled.div`
  padding: 5px 0px 5px 10px;
  color: #676767;
  border-bottom: 1px solid rgb(169, 169, 169);
  font-size: 14.5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ArrowWrapper = styled.div``;

const Description = styled(motion.div)`
  overflow: hidden;
  letter-spacing: 1px;
`;

const ArrowIcon = styled(MdKeyboardArrowDown)`
  height: 30px;
  width: 30px;
  color: rgb(105, 105, 105);
`;

const TitleDescription = styled.div`
  font-size: 13px;
  letter-spacing: 4px;
  margin-left: 15px;
  color: solid #808080;
`;

const accordionVariants = {
  expanded: {
    height: "auto",
    opacity: 1,
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
  collapsed: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      when: "afterChildren",
    },
  },
};



const RelatedProductsGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ProductName = styled.div`
  position: absolute;
  bottom: 0;
  left: 7px;
  font-size: 11px;

  padding: 2px 5px;
  letter-spacing: 1px;
`;

const ProductPrice = styled.div`
  position: absolute;
  bottom: 0;
  right: 7px;
  font-size: 11px;

  letter-spacing: 1px;
  padding: 2px 5px;
`;

const RelatedProductSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

const RelatedProductImage = styled.img`
  width: 100%;
  opacity: 1; // fully visible by default
  transition: opacity 0.3s ease-in-out; // transition effect for opacity changes

  &:hover {
    opacity: 1; // semi-transparent when hovered over
  }
`;


const RelatedProductTitle = styled.h1`
  text-align: center;
  font-size: 19px;
  letter-spacing: 3px;
`;

const FooterSpacer = styled.div`
  height: 20vh;
  width: 100%;

  @media (max-width: 768px) {
    height: 5vh;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: calc(30% - 12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 10px;

  @media (max-width: 992px) and (min-width: 769px) {
    width: calc(50% - 20px);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &.hide-on-smaller,
    &.hide-on-smaller-screens {
      display: none;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    margin-right: 0;
  }
`;


const OptionContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OptionBoxContainer = styled.div`
  display: flex;
`;

const OptionBox = styled.div`
  width: 20px;
  height: 27px;
  text-align: center;
  line-height: 30px;
  padding: 3px 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 13px;

  border: 1px solid transparent;

  background-color: ${(props) => (props.isSelected ? "black" : "#f5f5f5")};
  color: ${(props) => (props.isSelected ? "white" : "black")};

  &:hover {
    background-color: ${(props) => (!props.isSelected ? "white" : "black")};
    border-color: ${(props) => (!props.isSelected ? "black" : "transparent")};
  }
`;

const OptionLabel = styled.div`
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 7px;
`;


const slickContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: first start; 
  padding: 10px;
  margin-right: 20px;
  max-width: calc(50% - 20px);
  background-color: red;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }
`;


const CardImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
`;





const SinglePgTest = () => {
  const { id } = useParams();
  const [product, setProduct] = useState();

  const [images, setImages] = useState([ClothingImage, ClothingImage2]);
  const { cart, addToCart } = useContext(CartContext);
  const { conversionRate, currencySymbol, currecnyCode } = useContext(CurrencyContext);


  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // set initial value based on window width


  const settings = {
    dots: true, // Shows dot indicators at the bottom
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    arrows: false, // Hide arrows
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };


  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/get-product/${id}`
        );
        setProduct(response.data);

        // Common images for all products, filter out any NULL or empty values
        const commonImages = [
          response.data.ImageLink,
          response.data.ImageLink2,
          response.data.CloseUpImage,
          response.data.DetailImageLink2,
          response.data.ImageLink3
        ].filter(image => image);

        // Conditionally add option images for Chantelle dress (Product ID 3)
        const allImages = response.data.ID === 3
          ? [
            ...commonImages,
            response.data.Option1,
            response.data.Option2,
            response.data.Option3
          ].filter(image => image)
          : commonImages;

        setImages(allImages);
        setMainImage(response.data.ImageLink);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);



  const [productsRandom, setRandomProducts] = useState([]);

  useEffect(() => {
    const fetchRandomProducts = async () => {
      try {
        const response = await axios.get(
          `https://7v953ghjj6.execute-api.eu-west-1.amazonaws.com/test/get-random-products/3/${id}`
        );
        setRandomProducts(response.data);
      } catch (error) {
        console.error("Error fetching random products:", error);
      }
    };

    fetchRandomProducts();
  }, [id]);



  const handleAddToCart = () => {
    const item = {
      id: product.ID,
      name: product.Name,
      price: product.Price,
      size: selectedSize,
      image: product.CloseUpImage,
      priceId: product.StripeID,

    };

    addToCart(item, selectedOption);
  };

  const nextImage = () => {
    let nextIndex = images.indexOf(mainImage) + 1;
    if (nextIndex >= images.length) nextIndex = 0;
    setMainImage(images[nextIndex]);
    setSelectedThumbnail(nextIndex);
  };

  const prevImage = () => {
    let prevIndex = images.indexOf(mainImage) - 1;
    if (prevIndex < 0) prevIndex = images.length - 1;
    setMainImage(images[prevIndex]);
    setSelectedThumbnail(prevIndex);
  };

  const [selectedThumbnail, setSelectedThumbnail] = useState(0);

  const [mainImage, setMainImage] = useState(images[0]);

  const [selectedSize, setSelectedSize] = useState("XS");
  const [selectedOption, setSelectedOption] = useState("A1");

  const [expandedSection, setExpandedSection] = useState("description");





  const handleExpand = (section) => {
    setExpandedSection((prevSection) =>
      prevSection === section ? "" : section
    );
  };

  const [showNotification, setShowNotification] = useState(false);




  if (!product) {
    return <div>Loading...</div>;
  }


  return (
    <Container>



      <NavbarSpacer />


      {isMobile ? (
        <div>
        <BoxGroup>
          <LeftContainer>
            <ImageWrapper>
            <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <Image src={image} alt={`Carousel ${index + 1}`} />
          </div>
        ))}
      </Slider>
            </ImageWrapper>
          </LeftContainer>
          <RightContainer>
            <InfoContainer>
              <Title>{product.Name}</Title>
              <Price>{currencySymbol}{(Number(Math.round(product.Price * conversionRate * 10) / 10).toFixed(2))}</Price>

              <ColoursContainer>
                <ColoursLabel>COLOUR</ColoursLabel>

                <ColourOfClothing borderColor={product.Colour} />
              </ColoursContainer>

              {product.ID === 3 && (  // Ensure this comparison is correct; it might be '3' if ID is a string
                <OptionContainer>
                  <OptionLabel>OPTIONS</OptionLabel>
                  <OptionBoxContainer>
                    {["A1", "A2", "A3"].map((option) => (
                      <OptionBox
                        key={option}
                        isSelected={selectedOption === option} // Make sure selectedOption is the correct type for comparison
                        onClick={() => setSelectedOption(option)}
                      >
                        {option}
                      </OptionBox>
                    ))}
                  </OptionBoxContainer>
                </OptionContainer>
              )}




              <SizeContainer>
                <SizeLabel>SIZE</SizeLabel>
                <SizeBoxContainer>
                  {["XS", "S", "M", "L", "XL"].map((size) => (
                    <SizeBox
                      key={size}
                      isSelected={selectedSize === size}
                      onClick={() => {
                        setSelectedSize(size);

                      }}
                    >
                      {size}
                    </SizeBox>

                  ))}
                </SizeBoxContainer>
              </SizeContainer>

              <CartContainer>
                <AddtoCart onClick={handleAddToCart}>ADD TO CART</AddtoCart>
              </CartContainer>
              <Line />
              <DescriptionHeader onClick={() => handleExpand("description")}>
                <TitleDescription>DESCRIPTION</TitleDescription>
                <ArrowWrapper>
                  <motion.div
                    animate={{
                      rotate: expandedSection === "description" ? 180 : 0,
                    }}
                  >
                    <ArrowIcon />
                  </motion.div>
                </ArrowWrapper>
              </DescriptionHeader>
              <Description
                variants={accordionVariants}
                initial="collapsed"
                animate={
                  expandedSection === "description" ? "expanded" : "collapsed"
                }
              >
                <DescriptionContent>
                  {product.Description}
                </DescriptionContent>
              </Description>

              <DescriptionHeader onClick={() => handleExpand("sizeGuide")}>
                <TitleDescription>SIZE GUIDE</TitleDescription>
                <ArrowWrapper>
                  <motion.div
                    animate={{
                      rotate: expandedSection === "sizeGuide" ? 180 : 0,
                    }}
                  >
                    <ArrowIcon />
                  </motion.div>
                </ArrowWrapper>
              </DescriptionHeader>
              <Description
                variants={accordionVariants}
                initial="collapsed"
                animate={
                  expandedSection === "sizeGuide" ? "expanded" : "collapsed"
                }
              >
                <DescriptionContent>
                  {product.SizeGuide}
                </DescriptionContent>
              </Description>

              <DescriptionHeader onClick={() => handleExpand("compCare")}>
                <TitleDescription>COMPOSITION & CARE</TitleDescription>
                <ArrowWrapper>
                  <motion.div
                    animate={{
                      rotate: expandedSection === "compCare" ? 180 : 0,
                    }}
                  >
                    <ArrowIcon />
                  </motion.div>
                </ArrowWrapper>
              </DescriptionHeader>
              <Description
                variants={accordionVariants}
                initial="collapsed"
                animate={
                  expandedSection === "compCare" ? "expanded" : "collapsed"
                }
              >
                <DescriptionContent>
                  {product.CompositionCare}
                </DescriptionContent>
              </Description>
            </InfoContainer>
          </RightContainer>
        </BoxGroup>

      <RelatedProductSection>
        <RelatedProductTitle>RELATED PRODUCTS</RelatedProductTitle>
        <RelatedProductsGrid
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {productsRandom.map((product, index) => (
            <StyledLink
              to={`/product/${encodeURIComponent(product.Name)}/${product.ID}`}
              key={index}
              onMouseEnter={() => setHoveredProduct(product.ID)}
              onMouseLeave={() => setHoveredProduct(null)}
            >
              <RelatedProductImage
                src={hoveredProduct === product.ID ? product.ImageLink2 : product.ImageLink}
                alt={`Related Product ${product.Name}`}
              />
              <ProductName>{product.Name}</ProductName>
              <ProductPrice>
                {currencySymbol}{(Number(Math.round(product.Price * conversionRate * 10) / 10).toFixed(2))}
              </ProductPrice>
            </StyledLink>
          ))}
        </RelatedProductsGrid>
      </RelatedProductSection>
      <FooterSpacer />
      </div>
      ) : (
        <div>
        <Wrapper>
        <BoxGroup>
          <LeftContainer>
            <ThumbnailContainer>
              {images.map((image, index) => (
                <Thumbnail
                  isSelected={selectedThumbnail === index}
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => {
                    setMainImage(image);
                    setSelectedThumbnail(index);
                  }}
                />
              ))}
            </ThumbnailContainer>

            <ImageWrapper>
              <LeftArrowContainer onClick={prevImage}>
                <SlArrowLeft size={24} />
              </LeftArrowContainer>
              <Image src={mainImage} />
              <RightArrowContainer onClick={nextImage}>
                <SlArrowRight size={24} />
              </RightArrowContainer>
            </ImageWrapper>
          </LeftContainer>
          <RightContainer>
            <InfoContainer>
              <Title>{product.Name}</Title>
              <Price>{currencySymbol}{(Number(Math.round(product.Price * conversionRate * 10) / 10).toFixed(2))}</Price>

              <ColoursContainer>
                <ColoursLabel>COLOUR</ColoursLabel>

                <ColourOfClothing borderColor={product.Colour} />
              </ColoursContainer>

              {product.ID === 3 && (  // Ensure this comparison is correct; it might be '3' if ID is a string
                <OptionContainer>
                  <OptionLabel>OPTIONS</OptionLabel>
                  <OptionBoxContainer>
                    {["A1", "A2", "A3"].map((option) => (
                      <OptionBox
                        key={option}
                        isSelected={selectedOption === option} // Make sure selectedOption is the correct type for comparison
                        onClick={() => setSelectedOption(option)}
                      >
                        {option}
                      </OptionBox>
                    ))}
                  </OptionBoxContainer>
                </OptionContainer>
              )}




              <SizeContainer>
                <SizeLabel>SIZE</SizeLabel>
                <SizeBoxContainer>
                  {["XS", "S", "M", "L", "XL"].map((size) => (
                    <SizeBox
                      key={size}
                      isSelected={selectedSize === size}
                      onClick={() => {
                        setSelectedSize(size);

                      }}
                    >
                      {size}
                    </SizeBox>

                  ))}
                </SizeBoxContainer>
              </SizeContainer>

              <CartContainer>
                <AddtoCart onClick={handleAddToCart}>ADD TO CART</AddtoCart>
              </CartContainer>
              <Line />
              <DescriptionHeader onClick={() => handleExpand("description")}>
                <TitleDescription>DESCRIPTION</TitleDescription>
                <ArrowWrapper>
                  <motion.div
                    animate={{
                      rotate: expandedSection === "description" ? 180 : 0,
                    }}
                  >
                    <ArrowIcon />
                  </motion.div>
                </ArrowWrapper>
              </DescriptionHeader>
              <Description
                variants={accordionVariants}
                initial="collapsed"
                animate={
                  expandedSection === "description" ? "expanded" : "collapsed"
                }
              >
                <DescriptionContent>
                  {product.Description}
                </DescriptionContent>
              </Description>

              <DescriptionHeader onClick={() => handleExpand("sizeGuide")}>
                <TitleDescription>SIZE GUIDE</TitleDescription>
                <ArrowWrapper>
                  <motion.div
                    animate={{
                      rotate: expandedSection === "sizeGuide" ? 180 : 0,
                    }}
                  >
                    <ArrowIcon />
                  </motion.div>
                </ArrowWrapper>
              </DescriptionHeader>
              <Description
                variants={accordionVariants}
                initial="collapsed"
                animate={
                  expandedSection === "sizeGuide" ? "expanded" : "collapsed"
                }
              >
                <DescriptionContent>
                  {product.SizeGuide}
                </DescriptionContent>
              </Description>

              <DescriptionHeader onClick={() => handleExpand("compCare")}>
                <TitleDescription>COMPOSITION & CARE</TitleDescription>
                <ArrowWrapper>
                  <motion.div
                    animate={{
                      rotate: expandedSection === "compCare" ? 180 : 0,
                    }}
                  >
                    <ArrowIcon />
                  </motion.div>
                </ArrowWrapper>
              </DescriptionHeader>
              <Description
                variants={accordionVariants}
                initial="collapsed"
                animate={
                  expandedSection === "compCare" ? "expanded" : "collapsed"
                }
              >
                <DescriptionContent>
                  {product.CompositionCare}
                </DescriptionContent>
              </Description>
            </InfoContainer>
          </RightContainer>
        </BoxGroup>
      </Wrapper>

      <RelatedProductSection>
        <RelatedProductTitle>RELATED PRODUCTS</RelatedProductTitle>
        <RelatedProductsGrid
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {productsRandom.map((product, index) => (
            <StyledLink
              to={`/product/${encodeURIComponent(product.Name)}/${product.ID}`}
              key={index}
              onMouseEnter={() => setHoveredProduct(product.ID)}
              onMouseLeave={() => setHoveredProduct(null)}
            >
              <RelatedProductImage
                src={hoveredProduct === product.ID ? product.ImageLink2 : product.ImageLink}
                alt={`Related Product ${product.Name}`}
              />
              <ProductName>{product.Name}</ProductName>
              <ProductPrice>
                {currencySymbol}{(Number(Math.round(product.Price * conversionRate * 10) / 10).toFixed(2))}
              </ProductPrice>
            </StyledLink>
          ))}
        </RelatedProductsGrid>
      </RelatedProductSection>
      <FooterSpacer />
      </div>
      )}
    </Container>
  );
};

export default SinglePgTest;

