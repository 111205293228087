//FILENAME: src/Contexts/CartContext.js

import React, { createContext, useState, useEffect } from "react";  // Added useEffect to imports

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Moved the local storage functions up
  const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem('shoppingCart');
    if (savedCart) {
        return JSON.parse(savedCart);
    }
    return [];
  }
  
  const saveCartToLocalStorage = () => {
    localStorage.setItem('shoppingCart', JSON.stringify(cart));
  }

  const initialCart = loadCartFromLocalStorage();
  
  const [cart, setCart] = useState(initialCart);
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    saveCartToLocalStorage();
  }, [cart]);

  const addToCart = (item, selectedOption) => { // include selectedOption parameter
    setCart((prevCart) => {
      // Check if the item with the same id, size, and option already exists in the cart
      const existingItem = prevCart.find(
        (i) => i.id === item.id && i.size === item.size && i.option === selectedOption
      );
  
      if (existingItem) {
        // If such an item exists, just increase its quantity and update the total price
        return prevCart.map((i) =>
          i.cartItemId === existingItem.cartItemId
            ? {
                ...i,
                quantity: i.quantity + 1,
                total: (i.quantity + 1) * parseFloat(i.price),
              }
            : i
        );
      } else {
        // If no such item exists, add a new item to the cart with the provided size and option
        const newItem = {
          ...item,
          option: selectedOption, // Save selected option
          StripeID: item.StripeID,
          cartItemId: `${item.id}-${Date.now()}`,
          quantity: 1,
          total: parseFloat(item.price),
        };
  
        return [...prevCart, newItem];
      }
    });
    setIsCartOpen(true);
  };
  

  const clearCart = () => {
    setCart([]);  // Clear in-memory cart
    localStorage.removeItem('shoppingCart');  // Clear cart in localStorage
};


  const removeFromCart = (cartItemId) => {
    console.log("Removing item with ID:", cartItemId);
    console.log("Current cart items:", cart);
    setCart((prevCart) =>
      prevCart.filter((item) => item.cartItemId !== cartItemId)
    );
  };

  const updateCartItemQuantity = (cartItemId, newQuantity) => {
    setCart((prevCart) => {
      return prevCart.map((item) => {
        if (item.cartItemId === cartItemId) {
          return {
            ...item,
            quantity: newQuantity < 1 ? 1 : newQuantity,
            total: (newQuantity < 1 ? 1 : newQuantity) * parseFloat(item.price),
          };
        }
        
        return item;
      });
    });
  };

  const updateCartItemSize = (cartItemId, newSize) => {
    setCart((prevCart) => {
      return prevCart.map((item) => {
        if (item.cartItemId === cartItemId) {
          return {
            ...item,
            size: newSize
          };
        }
        return item;
      });
    });
  };
  

  return (
    <CartContext.Provider
        value={{
            cart,
            setCart,
            addToCart,
            removeFromCart,
            updateCartItemQuantity,
            updateCartItemSize, 
            isCartOpen,
            setIsCartOpen,
            clearCart  // Include the clearCart function here
        }}
    >
        {children}
    </CartContext.Provider>
);

};

